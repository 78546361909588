@use '/src/styling/base';

#quizCard {
  display: flex;
  flex-direction: column;
  /* Gray/900 */
  background: #101828;
  color: white;
  padding: 24px;
  @media (max-width: 715px) {
    padding: 20px;
  }

  .quizCardTitle {
    margin: 0;
    padding-bottom: 24px;

    border-bottom-width: 1px;
    border-bottom-color: #344054;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .nextQuizDayCount {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #344054;
  }
}
