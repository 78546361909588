#settings-card {
  h3,
  h2 {
    margin: 0;
  }
  font-family: 'Inter';
  font-style: normal;
  padding: 24px;
  .settings-title {
    /* Text md/Medium */
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    /* Gray/900 */
    color: #101828;
    margin-bottom: 24px;
  }

  section.settings-options {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* Gray/900 */
    color: #101828;

    div.settings-option {
      cursor: pointer;
      padding-top: 24px;
      padding-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-top: 1px solid #0000001f;
      &:last-of-type {
        border-bottom: 1px solid #0000001f;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .settings-backBtn-container {
    .settings-backBtn {
      width: fit-content;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      img {
        transform: rotate(180deg);
        margin-right: 12.17px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  #settings-logout {
    cursor: pointer;

    text-align: center;
    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */

    /* Error/500 */
    color: #f04438;
    margin-top: 24px;
    a {
      color: #f04438 !important;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

#userAccounts {
  div.account-item {
    &:first-of-type {
      margin-top: 16px;
    }
    &:last-of-type {
      margin-bottom: 16px;
    }
    span.lowerLine {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid #0000001f;
    border-bottom: 1px solid #0000001f;
  }
  .flinksModalBtn {
    margin-top: 24px;
  }
  .accountStatus {
    margin-bottom: 28px;
    /* Text xs/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    border: 0;

    &.synced {
      /* Success/700 */
      color: #027a48;
      /* Success/100 */
      background: #d1fadf;
    }

    &.noSync {
      /* Warning/700 */
      color: #b54708;
      /* Warning/100 */
      background: #fef0c7;
    }
  }

  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  div.titleArea {
    padding-bottom: 24px;
  }
  .accounts-title {
    margin: 0;
    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    /* Gray/900 */
    color: #101828;
  }
  p.muted {
    margin: 0;

    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* Gray/500 */
    color: #667085;
  }
}

.acctDetails {
  .acctDetails-item {
    h3 {
      /* Text md/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      /* Gray/900 */
      color: #101828;
    }
    p.muted {
      margin: 0;

      /* Text sm/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      /* Gray/500 */
      color: #667085;
    }
    &:first-of-type {
      margin-top: 16px;
    }

    border-top: 1px solid #0000001f;
    // border: 2px dashed green;
    padding-top: 12px;
    padding-bottom: 12px;

    &:last-of-type {
      border-bottom: 1px solid #0000001f;
      margin-bottom: 16px;
    }
  }
}

.pwdResetCard {
  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
