#seeResults {
  hr {
    margin-top: 32px;
  }
  p.muted {
    /* Text xs/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */

    text-align: center;

    /* Gray/400 */
    color: #98a2b3;
  }
}
