#ficoModal {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;

  p,
  h3,
  .btn-lg,
  h4 {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  .cardModal-contents {
    row-gap: 24px;
  }

  .closeBtnArea {
    display: flex;
    justify-content: flex-end;
    // margin-bottom: 24px;
    width: 100%;
    .closeBtn {
      cursor: pointer;
    }
  }
  hr {
    width: 100%;
    margin: 0;
  }
  h3 {
    /* Text lg/Medium */
    font-size: 18px !important;
    line-height: 28px;
    text-align: center;
    /* Gray/900 */
    color: #101828;
  }
  .ficoModalInfo,
  ol,
  .faqLink {
    margin: 0;
    text-align: left;
    h4 {
      /* Text sm/Medium */
      font-size: 14px;
      line-height: 20px;
      /* Gray/800 */
      color: #1d2939;
    }
    p,
    li {
      /* Text sm/Medium */
      font-size: 14px;
      line-height: 20px;
      /* Gray/600 */
      color: #475467;
    }
    a {
      /* Text sm/Medium */
      font-size: 14px;
      line-height: 20px;
      /* Success/700 */
      color: #027a48;
      //   cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  div.list {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  img.ficoBadge {
    align-self: flex-start;
  }
  .terms {
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    /* Gray/500 */
    color: #667085;
  }
}
