@use '/src/styling/base';
.streakBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;

  // display: flex;
  // flex-direction: column;
  max-width: 100%;
  width: 100%;
  // padding: 1em;
  color: black;
  border: 0;
  //   justify-content: center;
  //   align-content: center;
  .streakTitle {
    margin: 0;
    padding-bottom: 24px;
    width: 100%;
    text-align: center;
  }
  .streakInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .streakIcons {
      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: center;
      padding: 0px;
      gap: 4px;
      margin-bottom: 24px;
    }
    .streakReward {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #344054;
    }
  }
}
