#cashbackContainer {
  .cashbackInfo {
    padding-top: 24px;
    display: flex;
    h1 {
      margin: 0;
      padding: 0;
      /* Display md/Semibold */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      /* Gray/900 */
      color: #101828;
      margin-right: 6px;
      &.muted {
        color: #98a2b3;
      }
    }
  }
  h2.assessment {
    margin-top: 16px;
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    /* Gray/400 */
    color: #98a2b3;
  }

  hr {
    margin-top: 24px;
    margin-bottom: 0px;
  }
}
