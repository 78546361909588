@use '/src/styling/base';
.nextQuizBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Gray/900 */
  background: #101828;
  color: white;
  text-align: center;
  padding-top: 12px;
  .nextQuizBtn {
    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* Success/500 */
    background: #12b76a;
    &:hover {
      color: white;
      background-color: #039855; //green 200
    }
  }
  .quizCount {
    padding-top: 24px;
  }
}
