#assessment-results {
  width: 100%;
  .cardContainer {
    width: 100%;
  }

  .result-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    padding-top: 24px;
    p {
      margin-bottom: 0;
    }
    .textButton {
      margin-top: 0;
    }
    .tellMoreBtn {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  .divided > * {
    border-bottom: 1px solid #0000001f;
    // margin-top: 1em;
    &:last-child {
      border: 0;
    }
  }
  .assessment--disclaimer {
    margin-top: 32px;
  }
  p.muted {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    /* Gray/500 */
    color: #667085;
  }
  .assessment--disclaimer > p.muted {
    /* Text xs/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Gray/400 */
    color: #98a2b3;
  }
}
