footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0 0 0;
  height: fit-content !important;

  nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }

  .footer-legal{
    padding-top: 24px;
    margin-top: auto;
    font-size: 12px;
    border-top: 1px solid #e0e0e0;
  }

  div#footerContents {
    // display: flex;
    // flex-direction: column;
    // align-items: space-between;
    height: fit-content !important;

    #linkBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #667085; //grey500
      margin-bottom: 48px;
    }

    .links {
      display: flex;
      justify-content: flex-end;
      list-style: none;
      li {
        margin-left: 12px;
      }
    }

    .logo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .loginStatus {
      li {
        margin-left: 24px;
      }
    }

    .footerLinks {
      .loginBtn {
        width: 83px;
        height: 44px;
      }
      ul.links {
        margin-bottom: 0;
        display: flex;
        flex-direction: row;

        li {
          display: flex;
          justify-content: center;
          align-items: center;

          h2 {
            margin-bottom: 0;
          }
          img.linkImg {
            margin-right: 15px;
            @media (max-width: 715px) {
              display: none;
            }
          }
          // margin-bottom: 12px;
          text-align: end;
        }
      }
    }

    .footerLegal {
      color: #98a2b3; //grey400
      width: 100%;
      display: flex;
      justify-content: space-between;

      .legalLinks {
        align-self: flex-end;
        margin-bottom: 0;
        h3 {
          margin-bottom: 0;
        }
      }
      .copyright {
        max-width: 48%;
        display: flex;
        text-align: start;
        p.muted {
          color: #98a2b3; //grey400
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    @media (max-width: 1250px) {
      height: 357px;
    }

    @media (max-width: 715px) {
      footer {
        height: 380px;
      }
      .footerLegal {
        flex-direction: column;
        .copyright {
          max-width: 100%;
        }
        .legalLinks {
          margin-top: 12px;
          padding-left: 0;
          li {
            margin-left: 0;
            margin-right: 12px;
            &:last-child {
              margin-right: 0;
            }
          }

          align-self: flex-end;
        }
      }
    }
  }
  a {
    &:hover {
      text-decoration: none !important;
      /* Gray 900 */
      color: #101828 !important;
    }
  }
}
