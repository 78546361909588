.iconButton {
  display: grid;
  place-items: center;
  font-size: 28px;
  /* Gray/100 */
  background-color: #f2f4f7;
  /*   Gray/700 */
  color: #344054;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;

  &:hover {
    /* Gray/200 */
    background-color: #eaecf0;
  }
  .smallIcon {
    width: 23px;
    height: 23px;
  }
  img {
    height: 60%;
    width: 60%;
  }
  &.disabledBtn {
    /* White */
    background-color: #ffffff;
    border: 0;
    cursor: default;
    user-select: none;
  }
  &.noHover {
    /* Gray/100 */
    background-color: #f2f4f7;
    cursor: default;
  }
  &.selected {
    /* White */
    background-color: #ffffff;
    /* Gray/300 */
    border: 1px solid #d0d5dd;
    cursor: default;
  }
}
