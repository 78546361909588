div.assessment--disclaimer {
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #0000001f;
  p {
    margin: 0;
    /* Text xs/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* identical to box height, or 150% */
    text-align: center;
    /* Gray/400 */
    color: #98a2b3;
  }
}
