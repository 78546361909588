#cbHistory-discrepancy {
  .cardModal-contents {
    width: 100%;
  }
  .discrepancy-details {
    width: 100%;
    padding: 16px;
    box-shadow: none;
    margin-top: 24px;
    margin-bottom: 20px;
    hr {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  a {
    /* Success/600 */
    color: #039855;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .wideBtn {
    margin-top: 32px;
  }
}

#cbContactSpt {
  /* Text sm/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  padding: 16px;
  // gap: 4px;

  /* Warning/25 */
  background: #fffcf5;

  /* Warning/500 */
  border: 1px solid #f79009;
  border-radius: 8px;
  .linkText {
    margin-bottom: 0;
    cursor: pointer;
    /* Success/700 */
    color: #027a48;
    &:hover {
      text-decoration: underline;
    }
  }
}
