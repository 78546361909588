.textButton {
  text-align: center;
  cursor: pointer;
  /* Text md/Medium */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* Gray/500 */
  color: #667085;
  margin-top: 12px;
  margin-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
}
