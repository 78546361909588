@use '/src/styling/base';
#loanDetails {
  .titleLine {
    color: #101828;
    margin: 0;
    padding-bottom: 24px;
  }
  .loanInfo {
    color: #344054;
    padding-top: 24px;
    padding-bottom: 24px;
    .loanInfo-body {
      color: #667085;
    }
  }
  :last-child {
    padding-bottom: 0;
  }
  .errorNotification {
    margin-top: 24px;
    // /* Warning/500 */
    // border: 1px solid #f79009 !important;
  }
}
