.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-button {
  background-color: #ddd;
  border: none;
  color: #555;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #5cb85c; /* Green color when active */
  color: white;
}

.toggle-button:hover {
  /* background-color: #ccc; */
}