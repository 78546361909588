@use '/src/styling/base';
#monthlyGoalCard {
  // min-width: fit-content;
  font-family: 'Inter', system-ui;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  .goalHeader {
    .cardTitleWToolTip {
      padding-bottom: 24px;
    }
    padding-bottom: 24px;
  }

  .goalTiles {
    // padding-top: 24px;
    article.cardContainer.goalTile {
      box-shadow: none;
      padding: 16px;
      margin-bottom: 12px;
      width: 100%;
      display: flex;
      // justify-content: flex-start;
      // align-items: flex-start;
      flex-direction: column;
      gap: 12px;
      .tileContent {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .tileInfo > * {
          margin-bottom: 12px;
          &.btn {
            margin-top: 4px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        .tileInfo {
          .error {
            max-width: fit-content;
          }
          p.infoTitle {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #475467;
          }
        }
      }
    }
    .btn-outline-secondary {
      /* White */
      background: #ffffff;
      /* Gray/300 */
      border: 1px solid #d0d5dd;
      /* Gray/700 */
      color: #344054;
      &:hover {
        /* Gray/100 - Changed 10 June */
        background: #f2f4f7;
      }
    }
  }
  div.notifyContainer {
    p.xSmall {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 36px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .tileIcon {
    margin-right: 14px;
    width: 32px;
    height: 32px;
  }

  .btn {
    width: 104px;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
  }
}
#monthly-loanDetails {
  a.sptLink {
    cursor: pointer;
    /* Success/700 */
    color: #027a48 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
