#contactForm {
  padding: 24px;
  a {
    color: #12b76a !important;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  h2 {
    margin: 0;
    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* Gray/900 */
    color: #101828;
  }
  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .knowledgeBaseLink {
    margin-top: 24px;
    &.wideBtn {
      width: 100%;
    }
  }
  h3 {
    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* Gray/900 */
    color: #101828;
  }
  p.muted {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* Gray/500 */
    color: #667085;
    margin-bottom: 0px;
  }
  p.error {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* Error/500 */
    color: #f04438;
  }
  label {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* Gray/700 */
    color: #344054;
    margin-bottom: 6px;
  }
  .titleBlock {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;
    padding-bottom: 24px;
    img {
      margin-bottom: 24px;
    }
  }

  input {
    height: 44px;
    /* White */
    background: #ffffff;

    /* Gray/300 */
    border: 1px solid #d0d5dd;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-bottom: 24px;
  }
  textarea {
    resize: none;
    height: 88px;
    margin-bottom: 24px;
  }
  .wideBtn {
    &.disabled {
      /* Gray/200 */
      background: #eaecf0;

      /* Gray/200 */
      border: 1px solid #eaecf0;

      /* Shadow/xs */
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
}
