@use '/src/styling/base';

.loanInfo {
  display: flex;
  justify-content: space-between;
  padding: 0;
  // padding-left: 0;
  // padding-right: 0;
  color: base.$greyFontDark;
  * {
    margin: 0;
    padding: 0;
  }
  .loanInfo-title {
    font-weight: 800;
  }
  .loanInfo-body {
    color: base.$greyFontLight;
  }
}
