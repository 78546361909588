#goalsAssessment--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 400px;
  gap: 0;

  h1 {
    /* Display md/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    // line-height: 44px;
    /* identical to box height, or 122% */
    text-align: center;
    letter-spacing: -0.02em;

    /* Gray/900 */
    color: #101828;

    margin: 0;
    margin-top: 24px;
    margin-bottom: 12px;
  }
  hr {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .btn-lg {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    /* Success/500 */
    background: #12b76a;
    /* Success/500 */
    border: 1px solid #12b76a;
    width: 352px;
    height: 44px;

    font-weight: 500;
    font-size: 16px;
    &:hover {
      background-color: #039855; //green 200
    }
  }
  @media (max-width: 715px) {
    width: 336px;
    padding: 24px 10px;
    .btn-lg {
      width: 296px;
    }
    .assessmentButtons > * {
      width: 155.5px;
    }
  }
}
