#assessment-summary {
  margin-top: 32px;
  margin-bottom: 8px;
  padding: 16px;
  box-shadow: none;

  div.spaced {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    // border: 2px dashed red;

    // &:last-of-type {
    //   border: 2px dashed orange;

    //   padding-bottom: 0;
    // }
  }

  p,
  h4 {
    margin: 0;
  }

  #earnings.labelBubble {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* identical to box height, or 143% */

    /* Gray/700 */
    color: #344054;
    background-color: #f9fafb;
    border: #f9fafb;

    &.success {
      /* Success/700 */
      color: #027a48;
      /* Success/100 */
      background: #d1fadf;
      border: #d1fadf;
    }
  }

  .earningLine {
    padding-top: 0 !important;
    padding-bottom: 14px;
  }

  .summaryLine {
    // border: 2px dashed green;

    .missed-breakdown {
      padding-bottom: 16px;
      span.spaced {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        /* Text xs/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        /* Gray/500 */
        color: #667085;
      }
      strong {
        /* Text xs/Medium */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        /* Gray/700 */
        color: #344054;
      }
    }
  }
  padding-bottom: 0;
}
