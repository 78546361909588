section.feedbackContainer {
  // padding-top: 24px;
  padding-bottom: 0;
  h3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .labelBubble {
    border: none;
  }
  &:nth-last-of-type(1) {
    padding-bottom: 24px;
  }
  .disabledBtn:hover {
    background-color: #ffffff;
  }
}
