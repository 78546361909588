div#ficoScoreArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  background-color: #000000;
  border-radius: 24px;
  width: 336px;
  height: 206px;
  margin: 24px 0px;
  .scoreValueArea {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 12px;
    padding: 12px;
    width: 108px;
    height: 72px;
  }
  .score {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 48px !important;
    line-height: 100%;
    color: #000000;
    margin: 0;
  }
}
