@use '/src/styling/base';

h3 {
  margin: 0;
}

p {
  margin: 0;
  /* Text sm/Normal */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px;
}

label {
  /* Text sm/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Gray/700 */
  color: #344054;
}
.form-control::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.error {
  color: #f04438;
  width: 100%;
}
main {
  a {
    white-space: nowrap;
  }
}

.mr-8 {
  margin-right: 8px;
}
.center {
  text-align: center;
}
.cardForm {
  margin-top: 16px;
  div.mb-3:last-child {
    .form-control {
      margin-bottom: 0;
    }
  }
}
.wideBtn {
  margin-top: 24px;
  margin-bottom: 24px;
}

#preQualFAQ {
  padding-top: 48px;
  padding-bottom: 48px;

  /* Gray/100 */
  background: #f2f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  div.faqContent {
    width: 416px;
    padding-top: 32px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    p {
      font-size: 16px !important;
    }
    &:first-of-type {
      border-top: 0;
      padding-top: 0;
    }
  }

  ul#eligibilityList {
    padding-left: 16px;
    li {
      list-style: disc !important;
      list-style-position: outside !important;
      /* Text md/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      /* Gray/500 */
      color: #667085;
    }
  }
}

.applySupport {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 15px;
    width: 18px;
    height: 18px;
  }
  p {
    margin: 0%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* Gray/600 */
    color: #475467;
  }
  a {
    /* Success/600 */
    color: #039855 !important;
    white-space: nowrap;
    &:hover {
      color: #039855 !important;
      text-decoration: underline !important;
    }
  }
}
.securityInfo {
  p {
    margin: 0px;
  }
}
.otpInputArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.otpDigitInput {
  width: 48px;
  height: 64px;
  input {
    width: 100%;
    height: 100% !important;
    padding-left: 0;
    padding-right: 0;
    /* Display lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    &::placeholder {
      /* Display lg/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.02em;
      /* Gray/300 */
      color: #d0d5dd;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
}
.vertical-border {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 12px;
  margin-bottom: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.submissionNote {
  /* Text xs/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 18px;

  /* or 150% */
  text-align: center;

  /* Gray/400 */
  color: #98a2b3;
}
.titleBlock--icon {
  margin-bottom: 24px;
}
hr,
hr.surveryDivider {
  // border: 1px solid rgba(0, 0, 0, 0.12) !important;
  background-color: '#0000001F' !important;
}
.mv-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.surveyCheck {
  margin-bottom: 1em !important;
}

.heading--title {
  /* Text md/Medium */
  font-family: 'Inter';
  font-style: normal;
  // font-weight: 500;
  font-size: 18px !important;
  line-height: 24px;

  /* Gray/700 */
  // color: #344054;
}
.titleBlock--title {
  /* Text md/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px;

  /* Gray/700 */
  color: #344054;
}
.muted {
  /* Gray/500 */
  color: #667085;
}
.titleBlock--body {
  /* Text md/Normal */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.yearsAtResidence {
  margin-right: 12px;
}

header,
footer {
  margin: 0;
  width: 99vw;
  background-color: white; //white
  align-self: center;
}
header#applyHeader {
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // :nth-child(2) {
  //   justify-self: center;
  // }
  .progressSpan {
    justify-self: center;
    grid-column: 2;
  }
  // :nth-child(3) {
  //   justify-self: end;
  // }
  .applySupport {
    justify-self: end;
    grid-column: 3;
  }
  align-items: center;
  // position: relative;
  height: 80px;
  padding-left: 112px;
  padding-right: 112px;
  // a.logo {
  // position: absolute;
  // left: 112px;
  // }
  .progressSpan {
    width: 392px;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: absolute;
    // left: 33%;
    //     position: absolute;
    // top: 36px;
    .progress {
      width: 100%;
      /* Gray/200 */
      background: #eaecf0;
      border-radius: 4px;
    }
    .progressPercent {
      margin: 0;
      margin-left: 12px;
    }
  }
}
footer {
  // width: 100vw;
  height: fit-content !important;
  /* Gray/900 */
  background: #101828;
  /* Gray/200 */
  color: #eaecf0 !important;
  #applyFootContent {
    width: 100%;
    padding-left: 112px;
    padding-right: 112px;
  }
  .footerContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 8px;
    border-top: 1px solid #ffffff;
    margin-top: 48px;
  }
  .legal {
    width: 600px;
  }
  .legalLinks {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    h3 {
      margin: 0;
    }
    li {
      margin-left: 12px;
    }
  }
}

#preQualAppView {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .titleBlock--body {
    line-height: 20px;
  }
  .textButton {
    color: #98a2b3;
    margin-top: 0;
  }
  .eligibility {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 12px;
    p {
      margin: 0;
    }
    img {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }
  .pr12 {
    padding-right: 12px;
  }
  input,
  select {
    height: 44px;
    /* White */
    background-color: #ffffff;

    /* Gray/300 */
    border: 1px solid #d0d5dd;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &.form-check-input {
      height: 20px;
      width: 20px;
      border-radius: 10px;
      margin-right: 12px;
      &:checked {
        background-color: #039855; //Green 600
      }
    }
    &:focus {
      box-shadow: 0 0 0 0.25rem #98a2b380// #3c996e80;
    }
  }
  .termsCheckBox {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    cursor: default;
    p {
      margin: 0;
      cursor: pointer;
    }
  }
  #acceptTerms {
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    margin-right: 12px;
    /* White */
    background-color: #ffffff;
    /* Gray/300 */
    border: 1px solid #d0d5dd;
    border-radius: 6px;
  }
  ul#termsList {
    padding-left: 16px;
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* Gray/500 */
    color: #667085;
    li {
      list-style-position: outside;
      list-style-type: decimal;
      width: 100%;
    }
  }
  h3.greenBubble {
    // /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    // gap: 8px;

    // width: 336px;
    // height: 84px;

    /* Success/50 */
    background: #ecfdf3;
    border-radius: 12px;
    margin: 0;
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* Success/700 */
    color: #027a48;
    flex-grow: 1;
  }
}
div.debtRequestAmount {
  margin-bottom: 12px;
  h4 {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 143% */

    /* Gray/700 */
    color: #344054;
  }
  .badge {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;

    width: 101px;
    height: 28px;

    /* Blue light/100 */
    background: #e0f2fe;
    border-radius: 16px;
    margin-top: 12px;
  }
  p {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0;

    /* identical to box height, or 143% */
    text-align: center;

    /* Gray/700 */
    color: #344054;
  }
}

.inputDollar-container {
  position: relative;
  .inputDollar-symbol {
    position: absolute;
    top: 10px;
    left: 12px;

    /* Text md/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* Gray/500 */
    color: #667085;
  }

  input {
    padding-left: 33px;
  }
}

.inputPercent-container {
  position: relative;
  .inputPercent-symbol {
    position: absolute;
    top: 10px;
    left: 40px;

    /* Text md/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* Gray/500 */
    color: #667085;
  }

  // input.percent {
  // padding-left: 14px !important;
  // }
}

div.recoveryInfo {
  width: 400px;
  margin-top: 48px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div.recovery {
    text-align: center;
  }
  h2.recovery--title {
    /* Display sm/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 30px !important;
    color: #101828;
    margin-bottom: 20px;
  }
  p.recovery--body {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #667085;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .wideBtn {
    width: 352px;
  }
  .recovery-security {
    width: 336px;
    // align-self: center;
  }
}

#eligibilityLink {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  text-decoration-color: #98a2b3;
  a {
    /* Gray/400 */
    color: #98a2b3 !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  &:hover {
    text-decoration: none !important;
  }
}
/* change media q to tablet size */
@media (max-width: 1250px) {
  //Phone is 715px, but changes to tablet to fix nav on smaller view.

  /* Phone View */
  #preQualFAQ {
    padding: 24px 10px;
    .faqContent {
      width: 336px !important;
    }
    article.cardContainer {
      width: 336px;
    }
  }
  header#applyHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    a.logo {
      position: relative;
      left: auto;
      margin-top: 16px;
      margin-bottom: 0;
      //   align-self: center;
    }
    div#mobileHeaderDivider {
      margin-top: 24px;
      margin-bottom: 16px;
      border: 1px solid #f2f4f7;
      // background-color: #f2f4f71f;
      // color: #f2f4f71f;
      width: 100vw;
    }
    div.applySupport {
      text-align: center;
    }
    .progressSpan {
      width: 336px;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  footer#applyFooter {
    // div,
    .legal {
      width: 343px;
    }
    div#applyFootContent {
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 341px;
    }
    .footerContent {
      width: 343px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .legalLinks {
      margin-top: 16px;
      align-self: flex-start;
      padding-left: 0;
      li {
        margin-left: 0 !important;
        margin-right: 12px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
