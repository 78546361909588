#cbHistory-container {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* Gray/700 */
  color: #344054;

  .cbHistory--titleBlock {
    padding-top: 16px;
    > h3 {
      font-size: 14px;
      /* Gray/800 */
      color: #1d2939 !important;
      font-weight: 700 !important;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .history-divided,
  .loanInfo {
    width: 100%;
  }
  .history-divided {
    margin-bottom: 32px;
  }
  .history-divided > * {
    border-bottom: 1px solid #0000001f;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .loanInfo {
    &.reason {
      padding-top: 24px;
    }
  }

  .accordion {
    .accordion-button,
    .accordion-body {
      padding: 0;
    }
    .accordion-button {
      .loanInfo {
        padding-right: 11px;
      }
    }
  }

  .accordion-button:not(.collapsed) {
    background-color: inherit;
    box-shadow: inherit;
  }

  .cbHistory--info {
    .loanInfo-body {
      /* Success/600 */
      color: #039855;
    }
  }
  .errorNotification {
    margin-bottom: 24px;
  }
}
