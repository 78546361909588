@use '/src/styling/base';
.surveyCard.cardContainer {
  .titleLine {
    padding-bottom: 24px;
  }
  .questionContainer {
    h3.surveyQuestion {
      padding-bottom: 24px;
    }
  }
  .nextDayCount {
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    /* Gray/400 */
    color: #98a2b3;
    text-align: left;
    // padding-top: 24px;
    // border-top: 1px solid #0000001f;
  }

  p.feelingText {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;

    /* identical to box height, or 143% */
    text-align: center;

    /* Gray/700 */
    color: #344054;
  }
}
#inspireCard {
  div.errorNotification {
    margin-top: 24px;
  }
}
