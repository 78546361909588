#ficoScoreCard {
  p.ficoSummary {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* or 143% */

    /* Gray/600 */
    color: #475467;
  }
}

article.extraFICOInfo {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  margin-bottom: 24px;
  * {
    margin: 0;
  }
  h3 {
    /* Text md/Medium */
    font-size: 16px;
    line-height: 24px;
    /* Gray/900 */
    color: #101828;
  }
  section.scoreReasons {
    h4 {
      font-size: 14px;
      line-height: 20px;
      /* Gray/800 */
      color: #1d2939;
    }
    p {
      font-size: 14px !important;
      line-height: 20px;
      /* Gray/600 */
      color: #475467;
    }
    hr {
      margin: 24px 0px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  p.xsMuted {
    /* Text xs/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 18px;
    /* Gray/500 */
    color: #667085;
  }
  p.creditModalBtn {
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    /* Success/700 */
    color: #027a48 !important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
