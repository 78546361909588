div.errorNotification {
  h5.supportLink,
  h5.errorMsg {
    margin: 0;
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
  }
  h5.errorMsg {
    /* Gray/800 */
    color: #1d2939;
  }
  h5.supportLink,
  a {
    cursor: pointer;
    /* Success/700 */
    color: #027a48 !important;
    &:hover {
      text-decoration: underline;
    }
  }
  /* Warning/25 */
  background: #fffcf5;

  /* Warning/500 */
  border: 1px solid #f79009 !important;
  border-radius: 8px;
  width: 100%;
  // max-width: 336px;
  min-height: 72px;
  padding: 16px;
  text-align: left;
  &.noBorder {
    border: none !important;
    /* White */
    background: #ffffff !important;
  }
  &.quizStyling {
    /* Gray/900 */
    background-color: #101828 !important;
    text-align: center;

    h5.errorMsg {
      /* White */
      color: #ffffff;
    }
  }

  @media (max-width: 992px) {
    // max-width: 296px;
  }
}
