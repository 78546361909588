div.wideCntr {
  width: 100%;
}
button.wideBtn {
  width: 100%;
  height: 44px;
  // padding: 0;
  padding: 10px 18px;
  /* Success/500 */
  background: #12b76a;

  /* Success/500 */
  border: 1px solid #12b76a;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  font-weight: 500;
  font-size: 16px !important;

  &:hover {
    color: white;
    background-color: #039855; //green 200
  }

  &.btn-outline-secondary {
    /* White */
    background: #ffffff;
    /* Gray/300 */
    border: 1px solid #d0d5dd;
    /* Gray/700 */
    color: #344054;
    &:hover {
      /* Gray/200 */
      background: #eaecf0;
      // background-color: red;
    }
  }
  &.disabled {
    /* Gray/200 */
    background: #eaecf0;

    /* Gray/200 */
    border: 1px solid #eaecf0;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  @media (max-width: 715px) {
    // width: 323px;
    width: 296px;
  }
}
