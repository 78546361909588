div.infoBubble {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px !important;
  //   gap: 8px;

  width: 336px;
  min-height: 60px;

  /* Success/100 */
  background-color: #d1fadf !important;
  mix-blend-mode: multiply;
  border-radius: 16px;

  margin-bottom: 24px;

  p {
    margin: 0;
    /* Text xs/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 18px;

    /* Success/700 */
    color: #027a48;
  }
  img#moreInfo {
    height: 20px;
    width: 20px;
    margin-left: 15px;
    &:hover {
      //   text-decoration: underline;
      //   border-bottom: 1px solid #027a48;
      cursor: pointer;
    }
  }
}
#moreInfo-modal-content {
  section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;
    padding-bottom: 24px;
    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;

      div.infoBubble {
        display: flex;
        justify-content: center;
        padding: 12px 16px !important;
        height: 42px;
        margin-top: 16px;
      }
    }
  }
}
