@use '/src/styling/base';

.notifyContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
  background-color: base.$lightGreen;
  color: base.$green;
  width: fit-content;
  height: fit-content;
  padding: 4px 10px;
  gap: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  margin-top: 12px;
  margin-bottom: 12px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .notifyBtn {
    display: flex;
  }

  &.info {
    /*[]  Todo verify is still being used */
    background-color: #f9fafb;
    color: #344054;
    .notifyBtn {
      stroke: #344054;
    }
  }
  &.warning {
    /* Warning/100 */
    background: #fef0c7;
    /* Warning/700 */
    color: #b54708;
    .notifyBtn {
      /* Warning/500 */
      stroke: #f79009;
    }
  }
  &.error {
    /* Error/100 */
    background: #fee4e2;
    /* Error/700 */
    color: #b42318;
    .notifyBtn {
      /* Error/500 */
      stroke: #f04438;
    }
  }
  &.success {
    /* Success/100 */
    background: #d1fadf;
    /* Success/700 */
    color: #027a48;
    .notifyBtn {
      /* Success/500 */
      stroke: #12b76a;
    }
  }
}

.clientNotifications > .notifyContainer.info {
  /* Info class in notification area */
  background-color: base.$notifyInfo-background;
  color: base.$notifyInfo-text;
  .notifyBtn {
    stroke: base.$notifyInfo-icon;
  }
}
