// .popover-header {
//   background-color: white;
//   //   border-bottom: 0;
// }
.popover-body {
  //   padding-top: 0;
  .wellnessTip {
    /* Text xs/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    /* Gray/500 */
    color: #667085 !important;
  }
}
