#tellMore {
  p.muted {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    /* Gray/500 */
    color: #667085;
  }
  hr {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  form {
    label {
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* Gray/700 */
      color: #344054;
      margin-bottom: 6px;
    }
    textArea {
      resize: none;
      height: 128px;
      padding: 10px 14px;
      /* White */
      background: #ffffff;
      /* Gray/300 */
      border: 1px solid #d0d5dd;
      /* Shadow/xs */
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
    // margin-bottom: 12px;
  }
  .textButton {
    margin-top: 24px;
  }
  .thanks {
    margin-bottom: 12px;
  }
}
