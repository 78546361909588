.btn-toggle-menu{
  @media (min-width: 1200px) {
    display: none !important;
  }
}

#lendersSubmitRpt {
  display: flex;
  flex-direction: column;
  * > * {
    margin-bottom: 8px;
  }
}

#lenderHits {
  li {
    border: 2px solid rgb(201, 201, 201);
    margin-top: 8px;
  }
  li:nth-child(even) {
    div.container {
      background-color: rgb(228, 228, 228);
    }
  }
}
main {
  justify-content: flex-start !important;
}

@media print {
  header,
  footer,
  hr,
  .btn {
    display: none !important;
  }
  #adminView {
    font-size: medium;
    width: 100%;
    ::placeholder {
      opacity: 0;
    }
    .col {
      margin-bottom: 0px !important;
      display: flex;
      height: fit-content !important;
      width: fit-content !important;
      // border: 2px solid orange;
      align-items: center;
      // justify-content: center;
      align-items: center;
    }

    label {
      // border: 2px solid green;
      font-size: x-small;
      width: fit-content !important;
    }
    input {
      width: 100% !important;
      // border: 2px solid red;
      font-size: small;
    }
  }
}
