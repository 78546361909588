.questionContainer {
  margin: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 0;
  padding-right: 0;
  .inspireImg {
    margin-bottom: 24px;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  .questionOptions {
    // display: flex;
    // justify-content: flex-start;
    // list-style: none;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    &.mood {
      // justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 12px;
      @media (max-width: 1250px) {
        gap: 4px;
      }
    }
    * {
      // margin-right: 0.5em;
      margin-left: 0;
    }
    // @media (max-width: 992px) {
    // justify-content: space-around;
    // &.mood {
    //   justify-content: space-around;
    // }
    // }
  }
}
