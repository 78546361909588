.CircularProgressbar .CircularProgressbar-path {
  // fill: url(#conGradient);
  // stroke: url(#wellnessGradient);
  // stroke: red;
  // stroke: #039855;
  // stroke-width: 16 !important;
}

// .CircularProgressbar .CircularProgressbar-trail .CircularProgressbar-path {
// stroke-width: 16 !important;
// }

// svg {
//   width: 160px;
//   height: 160px;
// }

#scoreArea {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  width: 160px;
  height: 160px;
}
.scoreInfo {
  font-family: 'Inter';
  font-style: normal;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 73px;
  height: 50px;
  .score {
    font-weight: 600;
    font-size: 24px !important;
    // line-height: 32px;
    /* Gray/900 */
    color: #101828;
  }
  .scoreRange {
    /* Text xs/Medium */
    font-weight: 500;
    font-size: 12px !important;
    // line-height: 18px;
    /* Gray/500 */
    color: #667085;
  }
}
