div.modal--titleBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0;
  .titleBlock--icon {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }
  .titleBlock--title {
    /* Text lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    /* Gray/900 */
    color: #101828;
  }
  .titleBlock--body {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* or 143% */
    text-align: center;
    /* Gray/600 */
    color: #475467;
    margin-bottom: 0;
  }
}
