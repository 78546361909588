@use '/src/styling/base';
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

html{
  -webkit-overflow-scrolling: touch;
}

#root {
  display: flex;
  // width: 100%;
  // height: 100%;
  justify-content: center;
  min-width: 350px;
  background-color: base.$greyBackground; //root is grey, footer and nav are explicitly white background
  h1 {
    font-size: 36px; //2rem; //36px @ large
    font-weight: 600;
  }
  h2 {
    font-size: 16px; //1rem; //16px @ large
    font-weight: 500;
  }
  // h2.titleLine {
  //   padding-bottom: 16px; // 1rem;
  // }
  h2.light {
    font-size: 16px; // 1rem; //16px @ large
    font-weight: 400;
  }
  h3 {
    font-size: 14px; //0.9rem; //14px @large
    font-weight: 500;
  }
  p {
    font-size: 16px; //0.9rem; //16px @ large
    font-weight: 400;
  }
  p.xSmall {
    font-size: 12px; //0.8rem; //12px @large
    font-weight: 500;
    margin: 0;
  }
  a {
    text-decoration: none;
  }
  li {
    list-style: none;
  }
  .authStatusBtn {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .muted {
    color: #667085;
  }

  .divided > * {
    border-bottom: 1px solid #0000001f;
    // margin-top: 1em;
    &:last-child {
      border: 0;
    }
  }

  #changePassCard {
    width: 384px;
    @media (max-width: 992px) {
      width: 336px;
    }
    // @media (max-width: 600px) {
    //   width: 85%;
    // }
  }

  .appContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    
    main {
      // background-color: base.$greyBackground;
      // height: fit-content;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      padding-bottom: 0;
      flex-grow: 1;

      // .clientView {
      //   #dashboardNotificaitons {
      //     padding-bottom: 12px;
      //   }
      // }
      .clientApp {
        width: 100%;
        height: 100%;
        padding-bottom: 36px;
        &.grid {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 32px;
          place-content: center;
          @media (max-width: 1250px) {
            grid-template-columns: repeat(2, 2fr);
            .col2 {
              grid-row: span 2;
            }
          }
          @media (max-width: 768px) {
            grid-template-columns: 2fr;
            grid-template-areas: 'one';
            .col2 {
              grid-row: auto;
            }
          }
          @media (max-width: 425px) {
            grid-template-columns: 1fr;
            grid-template-areas: 'one';
            .col2 {
              grid-row: auto;
            }
          }
        }
        .cardContainer {
          margin-bottom: 24px;
        }
      }
    }
    main.adminMode {
      justify-content: flex-start;
    }

    footer,
    header {
      margin: 0 auto;
      min-height: 90px;
      width: 100%;
      background-color: white; //white
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 715px) { 
      }
      .logo{
        display: inline-block;
        margin-right: auto;
      }
    }    
  }
  iframe {
    border: 0;
    padding: 0;
    margin: 0;    
    min-width: 320px;
    width: 100%;
    min-height: 420px;
    height: fit-content;
  }
  .cell-wrap-text{
    white-space: normal;
  }
}

.grid-responsive{
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ag-theme-alpine{
  min-height: 720px !important;
}



.chat-container{
  height: 100%;
  flex-grow: 1;
  .navbar{
    padding-top: 0;
    padding-bottom: 0;
  }
}

.chat-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 100px;
  padding-top: 24px;
  flex-grow: inherit;
}
#dashboard{
  td,
  th {
    // margin: 0;
    // border-top-width: 0px;
    white-space: nowrap;
  }
  
  div {
    width: 910px;
    overflow-x: scroll;
    margin-left: 260px;
    overflow-y: visible;
    // padding-bottom: 1px;
  }
  
  .headcol {
    position: absolute;
    width: 250px;
    left:175px;
    top: auto;
    // border-top-width: 3px;
    /*only relevant for first row*/
    // margin-top: -3px;
    /*compensate for top border*/
  }
  
  .headcol:before {
    content: '';
  }
}

.ag-horizontal-left-spacer {
  visibility: hidden;
}

.ag-body-horizontal-scroll-viewport {
  overflow-x: auto;
}


#timeline{
  h4{
    font-size:small;
    color: grey;
    margin-bottom: 0em !important;
  }
}

#ciftable{
  width: 1250px;
}


#applicanttable{

    width: 1350px;
    overflow-x: scroll;
    // margin-left: 260px;
    overflow-y: visible;
    // padding-bottom: 1px;

   
  
}

#applicanttable1{

  width: auto;
  overflow-x: scroll;
  // margin-left: 260px;
  overflow-y: visible;
  // padding-bottom: 1px;

 

}
.text-colour-green-parachute {
  color: #027a48;
}

.modal-dialog{
  background-color: white !important;
}

#preQualAppView .eligibility img{
       margin: 0px !important;
       height: 20px !important;
}

/* make the customizations */
$theme-colors: (
    "info": tomato,
    "danger": teal
);

@import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

  #customRangeBar input:focus, #customRangeBar select:focus {
    box-shadow: 0 0 0 0 rgb(152 162 179 / 0%) !important; 
 }
 
 
 #customRangeBar input, #customRangeBar select {
     border: 0px solid #d0d5dd !important;
 }

 .ag-overlay-loading-wrapper {
    background-color: white !important;
    opacity: 1 !important;
 }



 #summaryComponent{


  
  td,
  th {
    // margin: 0;
    // border-top-width: 0px;
    white-space: nowrap;
  }
  
  div {
    width: 910px;
    overflow-x: scroll;
    margin-left: 320px;
    overflow-y: visible;
    // padding-bottom: 1px;
  }
  
  .headcol {
    position: absolute;
    width: 235px;
    left:175px;
    top: auto;
    // border-top-width: 3px;
    /*only relevant for first row*/
    // margin-top: -3px;
    /*compensate for top border*/
  }
  
  .headcol:before {
    content: '';
  }
  
  .long {
  }
}

.leadSummaryComponent{

  width: 100%;
  overflow-x: scroll;
  margin-left: 0px;
  overflow-y: visible;
  
  td,
  th {
    // font-size: 3px;
    // margin: 0;
    // border-top-width: 0px;
    white-space: nowrap;
  }
  
  div {
    // width: 510px;
    // overflow-x: scroll;
    // margin-left: 320px;
    // overflow-y: visible;
    // padding-bottom: 1px;
  }
  
  .headcol {
    // position: absolute;
    // width: 235px;
    // left:175px;
    // top: auto;
    // border-top-width: 3px;
    /*only relevant for first row*/
    // margin-top: -3px;
    /*compensate for top border*/
  }
  
  .headcol:before {
    content: '';
  }
  
  .long {
  }
}

.utm-0{
  background-color: #dacdeb9f !important;
}
.utm-1{
  background-color: #a6e2c9a5 !important;
}
.utm-2{
  background-color: #a7bbdac4 !important;
}
.utm-3{
  background-color: #e5abb6b1 !important;
}
.utm-4{
  background-color: #f6ec8fa2 !important;
}
    
.utm-5{
  background-color: #ace2a6ad !important;
}
.utm-6{
  background-color: #daa7a7be !important;
}
.utm-7{
  background-color: #e5c6aba4 !important;
}
.utm-8{
  background-color: #8ff6aca7 !important;
}
.utm-9{
  background-color: #e5afaba8 !important;
}
.utm-10{
  background-color: #f6ec8f84 !important;
}
      
.utm-11{
  background-color: #a6e2c9a8 !important;
}
.utm-12{
  background-color: #a7bbda !important;
}
.utm-13{
  background-color: #e5abb6a2 !important;
}
.utm-14{
  background-color: #f6ec8fb8 !important;
}
    
.utm-15{
  background-color: #a6e2c9a3 !important;
}
.utm-16{
  background-color: #a7bbda9a !important;
}
.utm-17{
  background-color: #e5abb6a5 !important;
}
.utm-18{
  background-color: #f6ec8fa7 !important;
}
.utm-19{
  background-color: #e5abb69e !important;
}
.utm-20{
  background-color: #f6ec8fa2 !important;
}
.utm-21{
  background-color: #a7bbda9a !important;
}
.utm-22{
  background-color: #e5abb69c !important;
}

.utm-23{
  background-color: #a7bbda79 !important;
}
.utm-24{
  background-color: #e5abb68f !important;
}
.utm-25{
  background-color: #f6ec8f94 !important;
}

#connect-widget {
  width:  100%;
  height: 100%;
  margin: 0 auto;
}