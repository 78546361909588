// @use "sass:map";
// @use '../styling/abstracts/colors' as c;
.cardContainer {
  /* White */
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  width: 100%;
  height: fit-content;
  padding: 24px;
  gap: 24px;
  
  // border: 2px solid map.get(c.$colors, 'cardBorder');
  // border: 2px solid base.$greyBarLight;

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  /* Inside auto layout */
  margin: 0px 0px;

  // @media (max-width: 992px) {
  //   width: 33%;
  // width: 400px;

  // }
  @media (min-width: 768px) {
    width: 100%;
    max-width: 425px;
    margin-left: auto;
    margin-right: auto;
  }
}
