#changePassCard {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 24px;
  .loginIcon {
    width: 28px;
    height: 14px;
  }
  h2,
  p,
  hr {
    margin: 0;
  }
  .titleBlock {
    h2 {
      /* Text md/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* Gray/900 */
      color: #101828;
    }
    p {
      margin-top: 8px;
      /* Text sm/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* Gray/500 */
      color: #667085;
    }
  }
  #updatePass-form {
    label {
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      /* Gray/700 */
      color: #344054;
    }
    .form-control {
      border-radius: 8px;
      /* White */
      background: #ffffff;
      /* Gray/300 */
      border: 1px solid #d0d5dd;
    }
    .wideBtn {
      margin-top: 8px;
      &.disabled {
        opacity: 100%;
        /* Gray/200 */
        background: #eaecf0;
        /* Gray/200 */
        border: 1px solid #eaecf0;
      }
    }
  }

  #marketingOptIn-section {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 24px;
    margin-bottom: 24px;
    input {
      margin-right: 8px;
      /* White */
      background: #ffffff;
      /* Gray/300 */
      border: 1px solid #d0d5dd;
      border-radius: 4px;
    }
    div {
      h3 {
        /* Text sm/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* Gray/700 */
        color: #344054;
        margin: 0;
      }
      p {
        /* Text sm/Normal */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* Gray/500 */
        color: #667085;
        margin: 0;
      }
    }
  }
}
