div.assessmentQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  //Title block is already styled
  div.assessmentButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 18px;

      width: 170px;
      height: 44px;

      /* White */
      // background: #ffffff;
      /* Gray/300 */
      // border: 1px solid #d0d5dd;
      /* Shadow/xs */
      // box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      &:first-of-type {
        margin-right: 12px;
      }
      // &:hover {
      //   background-color: #eaecf0; //Grey 200
      // }
    }
  }
}
