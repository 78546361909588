/* Deprecated, phase out */

$lightGreen: hsl(145, 81%, 96%);
$green: hsl(153, 96%, 30%);
$darkGreen: hsl(156, 88%, 16%);

$fontPurple: hsl(258, 54%, 52%);
$lightPurple: hsl(264, 100%, 98%);

$white: hsl(0, 0%, 100%);

$greyBackground: hsl(210, 20%, 98%);
$greyBarLight: hsl(217, 16%, 84%);
$greyFontDark: hsl(220, 43%, 11%);
$greyFontLight: hsl(221, 13%, 46%);
$greyBtnBackground: hsla(220, 22%, 96%, 1);
$greyDisabled: hsl(264, 100%, 98%);
$greyBtnFont: hsla(217, 23%, 27%, 1);

$quizBackground: hsla(221, 43%, 11%, 1);

/* Notification Colors */
$notifyInfo-text: #6941c6;
$notifyInfo-icon: #9e77ed;
$notifyInfo-background: #f9f5ff;

$notifyError-text: #b42318;
$notifyError-icon: #f04438;
$notifyError-background: #fef3f2;

$notifySuccess-text: #027a48;
$notifySuccess-icon: #12b76a;
$notifySuccess-background: #ecfdf3;

$notifyWarning-text: #b54708;
$notifyWarning-icon: #f79009;
$notifyWarning-background: #fffaeb;
