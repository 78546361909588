.modal-content {
  //Override Bootstrap styling
  background-color: transparent !important;
  border: 0 !important;
  place-items: center;
}
#onboardingContainer {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &.muted {
      color: #667085;
    }
  }
  .sptText {
    margin: 20px 0px 20px;
  }

  div.flinksSpinner {
    color: #039855;
  }
  #welcomeSlideContinue {
    text-align: center;
    cursor: pointer;
    /* Text md/Medium */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* Gray/500 */
    color: #667085;
    margin-top: 12px;
    margin-bottom: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  #onboard-pagination {
    // margin-top: 20px;
    margin-bottom: 32px;
    .page-item {
      width: 8px;
      height: 8px;
      border-radius: 4px;

      /* Inside auto layout */
      margin: 0px 8px;

      /* Gray/100 */
      background-color: #f2f4f7;
      &.active {
        /* Success/600 */
        background-color: #039855;
      }
      span {
        /* disable span link boxes from bootstrap*/
        display: none;
      }
    }
  }
  @media (max-width: 715px) {
    .onboard-img,
    .wideBtn,
    #onboardingBtn {
      width: 323px;
    }
  }
}

#onboarding-video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  img {
    position: absolute;
    height: 20px;
    width: 20px;
    top: -16px;
  }
  #onboarding-video-iframe {
    width: 801px;
    height: 497px;
  }

  @media (max-width: 715px) {
    width: 343px;
    height: 321.41px;

    img {
      top: 20px;
    }
    #onboarding-video-iframe {
      width: 343px;
    }
  }
}

#onboardingBtn,
.wideBtn {
  width: 352px;
  height: 44px;
  background: #12b76a;
  border: 1px solid #12b76a;
  color: #ffffff;

  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 0px;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  &:hover {
    background: #039855;
  }
}

div.onboard-survey {
  padding: 8px;
  .onboard-surveryDivider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.onboard-surveryTitle {
  /* Text lg/Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  /* or 156% */
  text-align: center;

  /* Gray/900 */
  color: #101828;

  margin: 0;
}
hr {
  margin-top: 24px;
  margin-bottom: 24px;
}
.onboard-surveryForm {
  text-align: left;
  // .onboard-surveryQuestion {
  // }
  // .onboard-surveryDivider {
  // }
  .onboard-surveyCheck {
    padding-bottom: 18px;
  }
  .wideBtn {
    margin-top: 0;
  }
}
