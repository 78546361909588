header {
  width: 100%;
  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .loginBtn {
      width: 83px;
      height: 44px;
    }
    ul.links {
      color: #667085; //grey500
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding-left: 0;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        img.linkImg {
          margin-right: 15px;
          @media (max-width: 715px) {
            display: none;
          }
        }

        h2 {
          margin: 0;
        }
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

nav{
  .navbar-links{
    color: #667085; //grey500
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    li{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
      .nav-link{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        color: #039855;
        &:hover, &:active{
          .link-icon{
            color: #039855;
            rect {
              fill: #039855;
            }
           
          }
          .link-text{
            color: #101828;
          }

        }
        &:focus{
          .link-icon{
            color: #039855;
            rect {
              fill: #039855;
            }
          }
          .link-text{
            color: #101828;
          }

        }
      }
      .link-icon{
        height: 24px;
        width: 24px;
        display: inline-block;
      }
      .link-text{
        margin-left: 0.55rem;
        @media (max-width: 715px) {
        //  display: none;
        }
      }
      &:last-child{
        padding-right: 0;
      }
      &:first-child{
        padding-left: 0;
      }
    }
  }
}

.navbar-nav{
  .nav-link{
    color: #039855;
    font-weight: 500;
    &.dropdown-toggle{
      background-color: #039855;
      color: white;
      border-radius: 6px;
      &.show{
        color: white;
      } 
    }
    &.active{
      color: white;
    }
    
  }
   
}
