@use '/src/styling/base';
.modal-backdrop,
.modal {
  /* Bootstrap had 2 background to the modal */
  // background-color: rgba(52, 64, 84, 0.35) !important;

  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    /* Firefox does NOT support backdrop filter */
    background-color: rgba(52, 64, 84, 0.45) !important;
  }
}
.modal-content {
  //Override Bootstrap styling
  background-color: transparent !important;
  border: 0 !important;
}

.tileIcon {
  margin-bottom: 20px;
}
.modal-content {
  //Bootstrap override
  place-items: center;
  .cardModal-container {
    row-gap: 0;
    width: 400px;
    @media (max-width: 715px) {
      width: 343px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .cardModal-contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 325px;

      .tileIcon {
        width: 48px;
        height: 48px;
      }
      h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #101828;
      }
      p.muted {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #667085;
        margin-bottom: 0;

        /* First para wont have a top margin, but all following will */
        margin-top: 8px;
        &:first-of-type {
          margin-top: 0;
        }
      }

      .loanInfo {
        display: flex;
        align-items: center;
        margin: 0 !important;

        .loanInfo-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
        }
        .loanInfo-body {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }

      .modalBtn {
        width: 323px;
        height: 44px;
        background: #12b76a;
        border: 1px solid #12b76a;
        color: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        place-self: center;
        text-align: center;
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 18px;
        font-weight: 500;
        font-size: 16px;
        &:hover {
          background: #039855;
        }
      }

      div.divided-Info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 24px;

        .loanInfo {
          border-top: 1px solid base.$greyBarLight;
          padding-top: 24px;
          padding-bottom: 24px;
        }

        div:last-child {
          border-bottom: 1px solid base.$greyBarLight;
        }
      }
    }
  }
}
