@use '/src/styling/base';

.quizBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Gray/900 */
  background: #101828;
  color: white;

  .quizQuestion {
    margin-bottom: 18px;
  }
  .quizOptions {
    display: flex;
    flex-direction: column;
    margin: 0;
    .quizBtn {
      margin-top: 6px;
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      opacity: 1 !important;
      /* Shadow/xs */
      // box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      &.btn-success {
        /* Success/100 */
        background: #d1fadf !important;
        /* Success/600 */
        border: 1px solid #039855 !important;
        /* Success/800 */
        color: #05603a !important;

        &:hover {
          // background: #039855;
          background: #a6f4c5 !important;
        }
      }
      &.btn-outline-secondary {
        /* Gray/900 */
        background: #101828;
        /* Gray/400 */
        border: 1px solid #98a2b3;
        /* Gray/400 */
        color: #98a2b3;
      }
      &.btn-outline-danger {
        /* Error/100 */
        background: #fee4e2;
        /* Error/500 */
        border: 1px solid #f04438;
        /* Error/500 */
        color: #f04438;
      }
    }
  }
  .answerTile {
    margin-top: 24px;
    padding-bottom: 12px;
    .response {
      font-weight: 700 !important;
      font-size: 14px !important;
    }
    .incorrect {
      /* Error/500 */
      color: #f04438;
      margin-bottom: 0;
    }
  }

  // =============================================================================Onboarding Quiz Styling
  &#onboardQuiz {
    /* Completely different style from the dashboard but same content and behaviour */
    display: flex;
    flex-direction: column;
    align-items: center;

    .borderedQuiz {
      padding: 24px 12px 12px;
      background: #f9fafb;
      border: 1px solid #d0d5dd;
      /* Shadow/xs */
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 10px;
      width: 352px;
    }
    background: #ffffff;

    margin-bottom: 8px;
    h2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #101828;
    }
    button.quizBtn {
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 18px;

      /* Success/500 */
      background: #12b76a;
      border-color: #12b76a;

      /* Shadow/xs */
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      // line-height: 24px;
      color: #ffffff;
      opacity: 1 !important;

      &:hover {
        background: #a6f4c5 !important;
      }
      &.btn-success {
        background: #d1fadf;
        border: 1px solid #039855;
        color: #05603a;
      }
      &.btn-outline-secondary {
        background: #f2f4f7;
        border: 1px solid #d0d5dd;
        color: #98a2b3;
      }
      &.btn-outline-danger {
        background: #fee4e2;
        border: 1px solid #b42318;
        color: #b54708;
      }
    }

    .answerTile {
      padding-top: 20px;
      p.response {
        /* Text sm / Bold */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #039855;
        /* identical to box height, or 143% */
        text-align: center;
        &.incorrect {
          color: red;
        }
        &.answer {
          color: #667085;
          font-weight: 500 !important;
          font-size: 14px;
        }
      }
      h3 {
        /* Text sm/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        /* or 143% */
        text-align: center;

        /* Gray/500 */
        color: #667085;
      }
    }
  }
}
