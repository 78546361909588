#monthly-wellness-survey {
  font-family: 'Inter';
  font-style: normal;
  .wellness-surveryTitle {
    /* Text lg/Medium */
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: start;
    /* Gray/900 */
    color: #101828;
  }
  .surveyItem {
    .surveyQuestion {
      /* Text sm/Normal */
      font-size: 14px;
      line-height: 20px;
      /* Gray/500 */
      color: #667085;
    }
    .surveyCheck {
      /* Text md/Medium */
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* Gray/700 */
      color: #344054;
    }
  }
  .wideBtn {
    margin-top: 0;
  }
}
