#wellnessScore {
  .v-dividers {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .cardTitleWToolTip {
    margin-bottom: 24px;
  }
  .progressBubble,
  .errorNotification {
    margin-top: 16px;
  }
  span.avgScoreInfo {
    h3 {
      margin: 0;
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      /* or 143% */

      /* Gray/800 */
      color: #1d2939;
    }
    .muted {
      /* Gray/500 */
      color: #667085;
    }
  }
  div.infoLine {
    h4 {
      margin: 0;
      /* Text xs/Semibold */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;

      /* Gray/500 */
      color: #667085;
    }
    p.muted {
      margin: 0;
      /* Text xs/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      /* Gray/500 */
      color: #667085;
    }
  }

  #wellnessLearnMore {
    /* Gray/300 */
    border: 1px solid #d0d5dd;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    /* Text sm/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 143% */

    /* Gray/700 */
    color: #344054;
  }
}
#wellness-details {
  /* Modal */
  h3 {
    margin-bottom: 24px;
    /* Text lg/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    text-align: center;
    /* Gray/900 */
    color: #101828;
  }
  h4 {
    margin: 0;
    /* Text md/Medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* Gray/700 */
    color: #344054;
  }

  p.disclaimer {
    margin: 0;
    /* Text xs/Semibold */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* Gray/500 */
    color: #667085;
  }
  .v-dividers {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 18px;
  }

  ul {
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding-left: 16px;
  }
  li {
    /* Text sm/Normal */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* Gray/500 */
    color: #667085;
  }
}
