#settings-pwd-reset {
  button.disabled {
    opacity: 100;
    /* Gray/200 */
    background: #eaecf0;

    /* Gray/200 */
    border: 1px solid #eaecf0;
  }
}
