button,
input {
  cursor: pointer;
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}

.App>main.chat-box,
.App>.nav-bar {
  width: 100%;
  overflow-x: hidden;
}

/* navbar */
.nav-bar {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #43d268;
  color: #ffffff;
  height: 60px;
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.btn-notification {
  >svg {
    height: 24px;
    width: 24px;
  }
}

.sign-in {
  border: none;
  background-color: transparent;
}

.sign-in>img {
  height: 30px;
  width: auto;
}

.sign-out {
  padding: 5px 10px;
  border-radius: 5px;
  color: #88dded;
  border: 1px solid #164335;
  background-color: #164335;
  font-weight: 600;
}

/* welcome page */
.welcome {
  padding: 30px;
  text-align: center;
  margin-top: 40px;
  color: #57b147;
}

.welcome :is(h2, p, img) {
  margin-bottom: 20px;
}

/* chat component */
.messages-wrapper {
  padding: 0 16px;
  width: 100%;
}

.chat-bubble {
  width: 100%;
  max-width: calc(100% - 50px);
  box-shadow: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: auto;
  align-items: flex-end;
}

.chat-bubble.left {
  margin-right: auto;
  align-items: flex-start;
}

.chat-bubble_content {
  display: flex;
  align-items: center;
  text-align: left;
}

.chat-bubble__left {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 10px;
  order: 2;
}

.chat-bubble__right {
  border-radius: 9px 9px 9px 0;
  padding: 15px;
  background-color: #EFF4FD;
  color: #000;
}

.chat-bubble.left .chat-bubble__right {
  border-radius: 9px 9px 0 9px;
  background-color: #027948;
  color: white;
}

.chat-bubble.left .chat-bubble__left {
  order: 1;
  margin-right: 0;
  margin-left: 10px;
}

.user-name {
  opacity: 0.75;
  margin-bottom: 5px;
}

.user-message {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.message-time {
  display: block;
  text-align: right;
}

/* send message */
.send-message {
  /* position: fixed; */
  bottom: 0px;
  width: 100%;
  padding: 20px 30px;
  background-color: #ffffff;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.send-message .form-control {
  /* position: fixed; */
  height: 60px;
  padding-right: 72px;
}

.send-message> :is(input, button):focus {
  outline: none;
}

.send-message>button {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 100%;
  color: #ffffff;
  border: 0;
  background-color: #027948;
  font-weight: 600;
  position: absolute;
  right: 50px;
  top: 50%;
  margin-top: -20px;
}

.send-message>button>svg {
  width: 28px;
  height: 28px;
}


.user-avatar-list {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.user-avatar-list .user {
  max-width: 62px;
  text-align: center;
  align-items: center;
}

.user-avatar-list .user:not(:first-child) {
  margin-left: 1rem;
}

.symbol {
  height: 48px;
  width: 48px;
  display: inline-block;
  flex-shrink: 0;
  position: relative;
}

.symbol>img,
.symbol>svg {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: #2a303e;
}

.symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  color: rgb(48, 48, 48);
  background-color: #d6f4e7;
}

.symbol-badge {
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 100%;
  top: 38px;
  left: auto;
  right: -15px;
  transform: translateX(-50%) translateY(-50%) !important;
  font-size: 10px;
  line-height: 18px;
  color: white;
  display: inline-block;
}

.form-search {
  position: relative;
  width: 100%;
}

.form-search .form-control-solid {
  color: #f8f8f8;
  padding-right: 60px;
  background-color: #242b3a;
  border-color: #2b3242;
}

.form-search .form-control-solid:focus,
.form-search .form-control-solid:hover {
  padding-right: 60px;
  background-color: #1d2433;
  border-color: #2b3242;
  color: #ffffff;
}

.form-search>.btn-search {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  color: white;
  padding: 0;
  background-color: #027948;
  position: absolute;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.form-search>.btn-search:hover,
.form-search>.btn-search:focus,
.form-search>.btn-search:active {
  background-color: #00a25e;
}

.btn-search>svg {
  display: inline-block;
}

.accordion-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border-color: #027948 !important;
}

.accordion-button:not(.collapsed) {
  color: #027948 !important;
  background-color: white !important;
  box-shadow: none !important;
}

.grid-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

grid-style {
  width: 100%;
}

.toastbox-grey {
  background-color: #F2F4F7 !important;
  color: '#344054' !important;
}


/*create class for the above !important for all*/

.planForm {
  h3 {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #667085 !important;

  }

  .card {
    width: Fill (352px) !important;
    height: Hug (136px) !important;
    padding: 16px !important;
    border-radius: 8px !important;
    border: 1px !important;
    gap: 8px !important;
    background: #F9FAFB !important;
    border: 1px solid #D0D5DD !important;

    .card-header {
      background-color: #F9FAFB !important;
    }

    .list-group-item {
      background-color: #F9FAFB !important;
    }

    h5 {
      font-family: Inter !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      letter-spacing: 0em !important;
      text-align: left !important;
      color: #101828 !important;
    }

    h7 {
      font-family: Inter !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      line-height: 18px !important;
      letter-spacing: 0em !important;
      text-align: left !important;
      color:  #667085 !important;
    }
  }

  .titleBlock--icon {
    width: 48px !important;
    height: 48px !important;
    padding: 12px !important;
    border-radius: 24px !important;
    background: #D1E9FF !important;

  }

  .titleBlock--body {

    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #101828 !important;


  }

  .plan-title {
    font-family: Inter !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: #101828 !important;
  }

  .selected-plan-checkbox {

    background: #ECFDF3 !important;
    border: 1px solid #6CE9A6;

    label {
      color: #05603A !important;
    }

    ;



    .form-check-input:checked {
      background-color: #039855;
      border-color: #05603A;
      width: 16px;
      height: 16px;
      padding: 2px;
      border-radius: 4px;
      border: 1px;

    }

  }

  .not-selected-plan-checkbox {

    background: #FFFFFF !important;

    border: 1px solid #EAECF0;

    label {
      color: #000 !important;
    }

    ;

    .form-check-input:checked {

      width: 16px;
      height: 16px;
      padding: 2px;
      border-radius: 4px;
      border: 1px;

    }

  }
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border-top: 1px solid #dddddd;
  display: flex;
  align-items: center;
}

.fixed-bottom input {
  flex: 1;
  margin-right: 10px;
}

.fixed-bottom button {
  flex-shrink: 0;
}