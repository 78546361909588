#loginCard {
  display: flex;
  flex-direction: column;
  padding: 24px;
  row-gap: 24px;

  margin-top: 24px;
  margin-bottom: 25px;

  .loginIcon {
    width: 28px;
    height: 14px;
  }
  .modal--titleBlock {
    align-items: flex-start;
    h3 {
      /* Text md/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      /* Text sm/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      /* Gray/500 */
      color: #667085;
    }
  }
  hr,
  p,
  h2 {
    margin: 0;
  }

  #login-form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .mb-3 {
      margin-bottom: 12px !important;
    }
    .form-control {
      border-radius: 8px;
      /* White */
      background-color: #ffffff; //Cant use background shorthand
      /* Gray/300 */
      border: 1px solid #d0d5dd;
    }
    .form-control.is-invalid {
      //Invalid icon that matchs the color the invalid text
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 5.33337V8.00004M7.99992 10.6667H8.00659M14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004Z' stroke='%23F04438' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    .invalid-feedback {
      /* Text sm/Normal */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* Error/500 */
      color: #f04438;
    }
    label {
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      /* Gray/700 */
      color: #344054;
    }
  }

  .loginOptions {
    display: flex;
    justify-content: space-between;
    align-content: center;
    @media (max-width: 1250px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 12px;
    }

    .rememberLogin {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      input {
        margin-right: 8px;
      }
      &:hover {
        text-decoration: underline;
      }
      * {
        cursor: pointer;
      }
      h2 {
        /* Text sm/Medium */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        /* Gray/700 */
        color: #344054;
      }
    }
    #forgotPassLink {
      cursor: pointer;
      /* Text sm/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      /* Success/700 */
      color: #027a48;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
