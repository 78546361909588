#green {
    color: #027a48;
}
#green-text {
    color: #027a48;
    font-size: 14px;
}

.justifytext{
    // text-align: justify;
    // text-justify: inter-word;
  }

  div.infoBubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px !important;
    //   gap: 8px;
  
    width: 336px;
    min-height: 60px;
  
    /* Success/100 */
    background-color: #eefff4 !important;
    mix-blend-mode: multiply;
    border-radius: 16px;
  
    margin-bottom: 24px;
    justify-content: center;
        align-items: center;
        text-align: center;
    p {
      
        
      margin: 0;
      /* Text xs/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 18px;
  
      /* Success/700 */
      color: #027a48;
    }
    img#moreInfo {
      height: 20px;
      width: 20px;
      margin-left: 15px;
      &:hover {
        //   text-decoration: underline;
        //   border-bottom: 1px solid #027a48;
        cursor: pointer;
      }
    }
  }

  div.blueBubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 12px 16px !important;
    //   gap: 8px;
  
    // width: 336px;
    min-height: 60px;
  
    /* Success/100 */
    background-color: #F2F4F7 !important;
    mix-blend-mode: multiply;
    border-radius: 16px;
  
    margin-bottom: 24px;
    justify-content: center;
        align-items: center;
        text-align: center;
    p {
      
        
      margin: 0;
      /* Text xs/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 18px;
  
      /* Success/700 */
      color: #344054 !important;
    }
    img#moreInfo {
      height: 20px;
      width: 20px;
      margin-left: 15px;
      &:hover {
        //   text-decoration: underline;
        //   border-bottom: 1px solid #027a48;
        cursor: pointer;
      }
    }
  }

  div.dangerBubble {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px !important;
    //   gap: 8px;
  
    width: 336px;
    min-height: 60px;
  
    /* Success/100 */
    background-color: rgb(199, 51, 51) !important;
    color: black !important;
    mix-blend-mode: multiply;
    border-radius: 16px;
  
    margin-bottom: 24px;
    justify-content: center;
        align-items: center;
        text-align: center;
    p {
      
        
      margin: 0;
      /* Text xs/Medium */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 18px;
  
      /* Success/700 */
     
      color: #fff !important;
    }
    img#moreInfo {
      height: 20px;
      width: 20px;
      margin-left: 15px;
      &:hover {
        //   text-decoration: underline;
        //   border-bottom: 1px solid #027a48;
        cursor: pointer;
      }
    }
  }