/* [] TODO clean up variables and DRY code */

.labelBubble {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 4px;
  border-radius: 18px;
  font-size: 14px;

  align-items: center;
  /* Gray/100 */
  background-color: #f2f4f7;
  // border: 1px solid #d0d5dd;
  /*   Gray/700 */
  color: #344054;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  user-select: none;

  :nth-child(2) {
    padding-left: 3px;
  }

  &.selected {
    /* White */
    background-color: #ffffff;
    /* Gray/300 */
    border: 1px solid #d0d5dd;
    &:hover {
      cursor: default;
      background-color: #ffffff;
    }
  }

  &.disabledBtn {
    /* used in community response section */
    /* White */
    background-color: #ffffff;
    cursor: default;
    user-select: none;
  }
  &.noHover {
    cursor: default;
    &:hover {
      /* Gray/100 */
      background-color: #f2f4f7;
    }
  }
  &.selected {
    /* White */
    background-color: #ffffff;
    /* Gray/300 */
    border: 1px solid #d0d5dd;
    cursor: default;
  }
  &:hover {
    /* Gray/200 */
    background-color: #eaecf0;
  }
}
